import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./components/navbar/Navbar"
import Home from "./components/homepage/Home";

export default function App() {

    return (
        <BrowserRouter>
            <>
                <Routes>
                    <Route path="*" element={<Navbar/>} />
                </Routes>

                <div style={{ display: 'block' }}>

                    <Routes>
                        <Route path="/" element={<Home/>} />
                        <Route path="*" element={<> not found </>} />
                    </Routes>
                </div>
            </>
        </BrowserRouter>
    );
}