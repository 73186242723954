import React from 'react'
import { Link } from 'react-router-dom';

import "./navbar.css"
import pk_logo from "../../static/images/pk_logo.png"

const Navbar = () => {
  return (
    <>
        <div className="navbar">

            <div className="glass_effect">

            <div className="logo">
                <img src={pk_logo}/>
            </div>

            <div className="pages_links">

                <Link>
                    <h6>Home</h6>
                </Link>

                <Link>
                    <h6>About Us</h6>
                </Link>

                <Link>
                    <h6>Services</h6>
                </Link>

                <Link>
                    <h6>Contact Us</h6>
                </Link>

            </div>

            <div className="quote_btn">
                <Link>
                    <button>Get a Quote</button>
                </Link>
            </div>

            </div>


        </div>
    </>
  )
}

export default Navbar