import React from 'react'
import { Link } from 'react-router-dom'

import "./home.css"
import hero_img from "../../static/images/hero_image.jpg"
import pk_logo from "../../static/images/pk_logo.png"
import footer_gradient from "../../static/images/footer_gradient.png"
import footer_image from "../../static/images/image_footer.png"
import drp_img from "../../static/images/drp_img.svg"
import sample_cs_sc from "../../static/images/sample_cs_sc.png"

const Home = () => {
  return (
    <>

      <div className="hero_section">
        <img src={hero_img} />

        <div className="main_hero_part">
          <h2>Simplifying <br /> Real Estate Transactions</h2>
        </div>

      </div>

      <div className="faqs_section">

        <h4>FAQs (Frequently Asked Questions)</h4>

        <div className="question">

          <div className="ques_drop">
            <h5>What types of properties are available through your company?</h5>
            <img src={drp_img} />
          </div>


          <p>We offer a diverse range of properties, including residential, commercial, and industrial real estate. Our residential options feature homes and apartments, while our commercial listings include office spaces and retail units. Additionally, we provide industrial properties such as warehouses and factories. Whether you're looking to buy, rent, or invest, we have the perfect property for you.</p>
        </div>

        <div className="question">

          <div className="ques_drop">
            <h5>What services does your company offer to property buyers?</h5>
            <img src={drp_img} />
          </div>


          <p>We provide comprehensive services for property buyers, including personalized property searches, expert market analysis, and assistance with negotiations and paperwork. Our goal is to make the buying process seamless and stress-free.</p>
        </div>

        <div className="question">

          <div className="ques_drop">
            <h5>How does your company ensure the quality and legality of its property listings?</h5>
            <img src={drp_img} />
          </div>

          <p>We ensure the quality and legality of our property listings through rigorous vetting processes, including thorough property inspections and verification of legal documentation. Our team also stays updated on regulatory compliance to provide trustworthy and accurate listings.</p>
        </div>

      </div>

      <div className="custom_section">

        <div className="cus_left">

          <div className="cnt_left">
            <img src={sample_cs_sc} />

            <h4>Something here</h4>

            <p>We offer a diverse range of properties, including residential, commercial.</p>
            <p>We offer a diverse range of properties, including residential.</p>

          </div>

        </div>

        <div className="cus_right">

          <div className="cnt_right">
            <img src={sample_cs_sc} />

            <h4>Something here</h4>

            <p>We offer a diverse range of properties, including residential, commercial.</p>
            <p>We offer a diverse range of properties, including residential.</p>

          </div>

        </div>

      </div>

      <footer>

        <img src={footer_gradient} />

        <div className="footermain">

          <div className="footer_left">

            <div className="footer_left_top">

              <img src={pk_logo} />

              <div className="pages_links_footer">

                <Link>
                  <h6>Home</h6>
                </Link>

                <Link>
                  <h6>About Us</h6>
                </Link>

                <Link>
                  <h6>Services</h6>
                </Link>

                <Link>
                  <h6>Contact Us</h6>
                </Link>

              </div>

            </div>

            <h2>For business inquiry</h2>

            <div className="footer_left_bottom">

              <div className="inquiry_details">

                <h2>Shahzeb Inamdar</h2>
                <h3>Phone - +91 1234567890</h3>
                <h6>Email - inquiry@propertykida.com</h6>

              </div>

              <div className="inquiry_details">

                <h2>Kafil Shaikh</h2>
                <h3>Phone - +91 1234567890</h3>
                <h6>Email - inquiry@propertykida.com</h6>

              </div>

            </div>

          </div>

          <div className="footer_right">

            <div className="footer_image_con">

              <div className="footer_image_card">
                <img src={footer_image} />
              </div>

            </div>

          </div>

        </div>

      </footer>
    </>
  )
}

export default Home;